import { SplitText } from 'gsap/SplitText';
import { wrap } from '../utils/dom';

export default function splitTextInit(container: HTMLElement | Document = document) {
    const elements = container.querySelectorAll<HTMLElement>('[data-split-lines]');
    elements.forEach((element) => {
        const splitted = new SplitText(element, {
            type: typeof element.dataset.splitChars !== 'undefined' ? 'chars,words,lines' : 'words,lines',
            reduceWhiteSpace: true,
            wordsClass: typeof element.dataset.splitChars !== 'undefined' ? 'splitted-chars-word' : '',
        });
        const { lines } = splitted;

        lines.forEach((lines) => {
            const wrapper = wrap(lines);
            wrapper.classList.add(
                typeof element.dataset.splitChars !== 'undefined' ? 'splitted-wrapper-chars' : 'splitted-wrapper-lines',
            );
        });
    });
}
