import { LitElement, css, html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import Cookies from 'js-cookie';

import _styles from './CookiesAgreement.ce.scss';

export interface CookiesAgreement {
    revealTimeout: number;
    _isVisible: boolean;
    _timer: NodeJS.Timeout;
}

/**
 * @attr {Number} reveal-timeout - Через сколько секунд сообщение покажется пользователю
 *
 * @slot - Контент в формате HTML
 */
export class CookiesAgreement extends LitElement {
    constructor() {
        super();
        this._acceptCookiesUsage = this._acceptCookiesUsage.bind(this);

        this.revealTimeout = this.getAttribute('reveal-timeout')
            ? parseFloat(this.getAttribute('reveal-timeout') as string)
            : 5000;
        this._isVisible = false;
    }

    static get properties() {
        return {
            revealTimeout: {
                type: Number,
                attribute: 'reveal-timeout',
            },
            _isVisible: {
                type: Boolean,
                attribute: false,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    connectedCallback() {
        super.connectedCallback();
        this._timer = setTimeout(() => {
            this._isVisible = true;
        }, this.revealTimeout);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._timer);
    }

    close() {
        this._isVisible = false;
    }

    protected _acceptCookiesUsage() {
        Cookies.set('COOKIES_USAGE_ACCEPTED', 'true', {
            expires: 365, // days
        });
        this.close();
    }

    render() {
        return html`
            <div class="${classMap({ banner: true, 'banner--visible': this._isVisible })}">
                <div class="banner__part">
                    <button
                        class="banner__close"
                        @click="${this._acceptCookiesUsage}"
                        aria-label="Принять и закрыть"
                        title="Принять и закрыть"
                    >
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line
                                x1="8.45532"
                                y1="22.5599"
                                x2="22.676"
                                y2="8.33916"
                                stroke="#A9AAC8"
                                stroke-linecap="round"
                            />
                            <line
                                x1="23.2287"
                                y1="22.0226"
                                x2="9.00798"
                                y2="7.80188"
                                stroke="#A9AAC8"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>

                    <div class="banner__title">Мы используем <br />куки</div>
                </div>
                <div class="banner__part">
                    <div class="banner__text">
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'app-cookies-agreement': CookiesAgreement;
    }
}

if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
    customElements.define('app-cookies-agreement', CookiesAgreement);
}
