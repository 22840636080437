import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';

import barba from '@barba/core';
import { preloader } from './components/preloader';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import BaseTransition from './transitions/base';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import vhMobileFix from '../modules/vh-mobile-fix';

import initDetailCardPopup from './inits/detail-card-popup';
import appNavInit from './inits/app-nav';

import { Tabs } from './custom-elements/Tabs/Tabs';
import { CategoriesBlock } from './custom-elements/CategoriesBlock/CategoriesBlock';

import 'swiper/css';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }
    initBaseView();
    calculateScrollbarWidth();
    vhMobileFix();
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    initDetailCardPopup();
    preloader
        .loadAssets()
        .then(() => window.scrollTo(0, 0))
        .then(() => preloader.leave())
        .then(() => {
            customElements.define('app-categories-block', CategoriesBlock);
            customElements.define('app-tabs', Tabs);
            barba.init({
                cacheIgnore: true,
                transitions: [BaseTransition],
                views: [
                    lazyView('index-page', () =>
                        import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('catalog-page', () =>
                        import(/* webpackChunkName: "catalog-page", webpackPrefetch: true */ './views/catalog').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('info-page', () =>
                        import(/* webpackChunkName: "info-page", webpackPrefetch: true */ './views/info').then(
                            (m) => m.default,
                        ),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                timeout: 5000,
                debug: NODE_ENV === 'development',
            });

            appNavInit();
        });
});
