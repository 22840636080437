/* eslint-disable max-lines */
import barba from '@barba/core';
import gsap from 'gsap';
import { viewport } from '../viewport';
import { getCSSCustomProp } from '../utils/css';
import videoPlayer from './video-player';

let appNavItems: HTMLElement[];
let popups: HTMLElement[];
let appNavElement: HTMLElement | null;
let appNavTrigger: HTMLElement | null;
let appNavMenuContainer: HTMLElement | null;
let appNavVideoElement: HTMLElement | null;
let overlay: HTMLElement | null;

let menuWidth: number;
let menuTimeline: gsap.core.Timeline;
let mobileMenuTimeline: gsap.core.Timeline;

let isPopupOpened = false;
let prevDevice: 'desktop' | 'touch';
let prevSlug = '';
let activeSlug = '';

const clickEvent = new Event('click');

let isLeaveAnimationPlaying = false;

const onClick = () => {
    if (appNavElement) {
        if (isPopupOpened) {
            appNavElement.classList.remove('is-open');
            mobileMenuTimeline.timeScale(2).reverse();
            closeActivePopup();
            unlockBodyScroll();
        } else {
            appNavItems[0].dispatchEvent(clickEvent);
            appNavElement.classList.add('is-open');
            mobileMenuTimeline.timeScale(1).restart();
            lockBodyScroll();
        }
    }
};

const onMouseEnter = () => {
    if (isPopupOpened || isLeaveAnimationPlaying) {
        return;
    }
    if (appNavElement) {
        menuTimeline.timeScale(1).restart();
    }
};

const onMouseLeave = () => {
    if (isPopupOpened) {
        return;
    }
    if (appNavElement) {
        isLeaveAnimationPlaying = true;
        menuTimeline
            .timeScale(2)
            .reverse()
            .then(() => {
                isLeaveAnimationPlaying = false;
            });
    }
};

const setActivePopup = async (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const trigger = eventTarget.closest<HTMLElement>('.js-app-nav-item');
    isPopupOpened = true;

    if (trigger) {
        prevSlug = activeSlug;
        activeSlug = trigger.dataset.trigger || '';
        appNavMenuContainer?.classList.add('is-visible');

        if (activeSlug === prevSlug) {
            return;
        }

        const prevActivePopup = popups.find((popup) => popup.dataset.name === prevSlug);
        const activePopup = popups.find((popup) => popup.dataset.name === activeSlug);
        const prevActiveTrigger = appNavItems.find((trigger) => trigger.dataset.trigger === prevSlug);

        if (prevActiveTrigger) {
            prevActiveTrigger.classList.remove('is-active');
        }

        const activeTrigger = appNavItems.find((trigger) => trigger.dataset.trigger === activeSlug);

        if (activeTrigger) {
            activeTrigger.classList.add('is-active');
        }

        if (activePopup) {
            if (prevActivePopup) {
                const prevActivePopupItems = Array.from(prevActivePopup.querySelectorAll('.a-app-nav-item'));
                const activePopupItems = Array.from(activePopup.querySelectorAll('.a-app-nav-item'));

                gsap.timeline()
                    .to(prevActivePopupItems, {
                        y: -20,
                        opacity: 0,
                        stagger: 0.05,
                        duration: 0.2,
                    })
                    .set(prevActivePopup, {
                        display: 'none',
                    })
                    .set(activePopup, {
                        display: 'flex',
                    })
                    .fromTo(
                        activePopupItems,
                        {
                            y: -20,
                            opacity: 0,
                            stagger: 0.05,
                            duration: 0.3,
                        },
                        {
                            y: 0,
                            opacity: 1,
                            duration: 0.3,
                            stagger: 0.05,
                        },
                    );
            } else {
                const activePopupItems = Array.from(activePopup.querySelectorAll('.a-app-nav-item'));

                gsap.timeline()
                    .set(activePopup, {
                        display: 'flex',
                    })
                    .fromTo(
                        activePopupItems,
                        {
                            y: -20,
                            opacity: 0,
                            duration: 0.3,
                            stagger: 0.05,
                        },
                        {
                            y: 0,
                            opacity: 1,
                            duration: 0.3,
                            stagger: 0.05,
                        },
                    );
            }
        }
    }

    if (appNavElement) {
        appNavElement.dispatchEvent(
            new CustomEvent('change-popup-event', {
                detail: 'open',
            }),
        );
    }

    lockBodyScroll();
};

const closeActivePopup = async () => {
    const activePopup = popups.find((popup) => popup.dataset.name === activeSlug);
    const activeTrigger = appNavItems.find((trigger) => trigger.dataset.trigger === activeSlug);
    prevSlug = '';
    activeSlug = '';

    if (activeTrigger) {
        activeTrigger.classList.remove('is-active');
    }

    if (activePopup) {
        const activePopupItems = Array.from(activePopup.querySelectorAll('.a-app-nav-item'));

        gsap.timeline()
            .to(activePopupItems, {
                y: -20,
                opacity: 0,
                stagger: 0.05,
                duration: 0.2,
            })
            .set(activePopup, {
                display: 'none',
            })
            .add(() => {
                appNavMenuContainer?.classList.remove('is-visible');
            });
    } else {
        appNavMenuContainer?.classList.remove('is-visible');
    }

    if (appNavElement) {
        appNavElement.dispatchEvent(
            new CustomEvent('change-popup-event', {
                detail: 'close',
            }),
        );
    }

    isPopupOpened = false;

    if (appNavElement) {
        if (window.matchMedia('(max-width: 1199px)').matches) {
            mobileMenuTimeline.timeScale(2).reverse();
            appNavElement.classList.remove('is-open');
        } else {
            menuTimeline.timeScale(2).reverse();
        }
    }

    unlockBodyScroll();
};

const onOverlayClick = () => {
    closeActivePopup();
};

const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        closeActivePopup();
    }
};

const handleTouchMove = (event: Event) => {
    const trigger = event.target as HTMLElement;

    // Если касание происходит внутри всплывающего окна, разрешаем прокрутку
    if (trigger.closest('.js-app-nav-popup')) {
        event.stopPropagation();
        return;
    }

    // Предотвращаем прокрутку на заднем фоне
    event.preventDefault();
};

const NO_SCROLL_CLASS = 'no-scroll';

const lockBodyScroll = () => {
    document.body.classList.add(NO_SCROLL_CLASS);
    // блокируем скрол на ios
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
};

const unlockBodyScroll = () => {
    document.body.classList.remove(NO_SCROLL_CLASS);
    document.removeEventListener('touchmove', handleTouchMove);
};

export default function appNavInit() {
    appNavElement = document.querySelector<HTMLElement>('.js-app-nav');
    appNavVideoElement = document.querySelector<HTMLVideoElement>('.js-app-nav-video');
    overlay = document.querySelector<HTMLElement>('.js-app-nav-menu-overlay');
    const appNavWrapper = appNavElement?.closest<HTMLElement>('.js-app-nav-wrapper');

    document.addEventListener('keydown', onKeyDown);

    if (overlay) {
        overlay.addEventListener('click', onOverlayClick);
    }

    const setPoster = (device: 'desktop' | 'mobile' = 'desktop') => {
        if (appNavVideoElement) {
            const desktopPoster = appNavVideoElement.dataset.posterDesktop || '';
            const mobilePoster = appNavVideoElement.dataset.posterMobile || '';
            if (device === 'desktop') {
                appNavVideoElement.setAttribute('poster', desktopPoster);
            } else {
                appNavVideoElement.setAttribute('poster', mobilePoster);
            }
        }
    };

    if (appNavElement && appNavVideoElement) {
        appNavElement.addEventListener('change-popup-event', (event: Event) => {
            if (appNavVideoElement && appNavVideoElement instanceof HTMLVideoElement) {
                appNavVideoElement.pause();
            }

            const detail = (event as CustomEvent).detail;

            if (detail === 'open') {
                document.body.classList.add('no-scroll');
            } else if (detail === 'close') {
                document.body.classList.remove('no-scroll');
            }
        });
    }

    if (appNavWrapper) {
        videoPlayer.init(appNavWrapper);
    }

    if (appNavElement) {
        if (viewport.width > 1799) {
            menuWidth = viewport.width * 0.3416;
        } else if (viewport.width < 1800 && viewport.width > 1199) {
            menuWidth = viewport.width * 0.375;
        } else if (viewport.width < 1200 && viewport.width > 768) {
            menuWidth = viewport.width - 40;
        } else {
            menuWidth =
                viewport.width - parseInt(getCSSCustomProp(document.documentElement, '--x-offset').toString(), 10) * 2;
        }

        appNavItems = Array.from(document.querySelectorAll<HTMLElement>('.js-app-nav-item'));
        popups = Array.from(document.querySelectorAll<HTMLElement>('.js-app-nav-popup'));
        appNavTrigger = document.querySelector('.js-menu-trigger');
        appNavMenuContainer = document.querySelector('.js-app-nav-menu-container');
        const appNavList = appNavElement.querySelector<HTMLElement>('.js-app-nav-list');
        const appNavLanguages = appNavElement.querySelector<HTMLElement>('.js-app-nav-languages');
        const languageItems = Array.from(appNavElement.querySelectorAll<HTMLElement>('.js-app-nav-language'));

        appNavItems.forEach((item) => {
            item.addEventListener('click', setActivePopup);
        });

        const setMenuTimeline = () => {
            if (menuTimeline && menuTimeline.kill) {
                menuTimeline.kill();
            }

            menuTimeline = gsap.timeline({
                paused: true,
            });

            menuTimeline
                .set(appNavTrigger, { display: 'none' })
                .to(
                    appNavElement,
                    {
                        width: menuWidth,
                        duration: 0.7,
                    },
                    0,
                )
                .set(
                    appNavList,
                    {
                        scaleY: 1,
                    },
                    0,
                )
                .set(
                    appNavList,
                    {
                        display: 'flex',
                    },
                    0.34,
                )
                .set(
                    appNavLanguages,
                    {
                        display: 'flex',
                    },
                    0.34,
                )
                .fromTo(
                    appNavItems,
                    {
                        y: 10,
                        opacity: 0,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'none',
                    },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'auto',
                    },
                    0.35,
                )
                .fromTo(
                    languageItems,
                    {
                        y: 10,
                        opacity: 0,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'none',
                    },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'auto',
                    },
                    0.35,
                );
        };

        const setMobileMenuTimeline = () => {
            if (mobileMenuTimeline && mobileMenuTimeline.kill) {
                mobileMenuTimeline.kill();
            }

            mobileMenuTimeline = gsap.timeline({
                paused: true,
            });

            mobileMenuTimeline
                .to(
                    appNavElement,
                    {
                        width: menuWidth,
                        duration: 0.7,
                        borderRadius: '8px 8px 0 0',
                    },
                    0,
                )
                .to(
                    appNavList,
                    {
                        scaleY: 1,
                        borderRadius: '0 0 8px 8px',
                        duration: 0.2,
                    },
                    0.2,
                )
                .fromTo(
                    appNavItems,
                    {
                        y: -10,
                        opacity: 0,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'none',
                    },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'auto',
                    },
                    0.35,
                )
                .to(
                    appNavLanguages,
                    {
                        display: 'flex',
                        duration: 0,
                    },
                    0,
                )
                .fromTo(
                    languageItems,
                    {
                        y: -10,
                        opacity: 0,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'none',
                    },
                    {
                        y: 0,
                        opacity: 1,
                        duration: 0.25,
                        stagger: 0.05,
                        pointerEvents: 'auto',
                    },
                );
        };

        setMenuTimeline();
        setMobileMenuTimeline();

        const onResize = () => {
            if (viewport.width > 1799) {
                menuWidth = viewport.width * 0.3416;
            } else if (viewport.width < 1800 && viewport.width > 1199) {
                menuWidth = viewport.width * 0.375;
            } else if (viewport.width < 1200 && viewport.width > 768) {
                menuWidth = viewport.width - 40;
            } else {
                menuWidth =
                    viewport.width -
                    parseInt(getCSSCustomProp(document.documentElement, '--x-offset').toString(), 10) * 2;
            }

            if (appNavWrapper) {
                appNavWrapper.style.setProperty('--app-nav-width', `${menuWidth}px`);
            }

            if (window.matchMedia('(min-width: 1200px)').matches && prevDevice !== 'desktop') {
                if (prevDevice === 'touch') {
                    if (appNavTrigger) {
                        appNavTrigger.removeEventListener('click', onClick);
                    }
                }
                if (appNavElement) {
                    appNavElement.addEventListener('mouseenter', onMouseEnter);
                    appNavElement.addEventListener('mouseleave', onMouseLeave);
                }
            } else if (window.matchMedia('(max-width: 1199px)').matches && prevDevice !== 'touch') {
                if (prevDevice === 'desktop') {
                    if (appNavElement) {
                        appNavElement.removeEventListener('mouseenter', onMouseEnter);
                        appNavElement.removeEventListener('mouseleave', onMouseLeave);
                    }
                }
                if (appNavTrigger) {
                    appNavTrigger.addEventListener('click', onClick);
                }
            }

            setPoster(window.matchMedia('(min-width: 1024px)').matches ? 'desktop' : 'mobile');
        };

        window.addEventListener('resize', onResize);
        onResize();

        barba.hooks.leave(() => {
            closeActivePopup();
        });
    }
}
