import debounce from 'lodash.debounce';

const onScroll = (event: Event) => {
    const scroller = event.target as HTMLElement;
    const indicator = scroller.querySelector<HTMLElement>('.js-hidden-scrollbar-indicator');

    if (indicator) {
        const isVertical = indicator.classList.contains('js-vertical');
        const scale = isVertical ? 'Y' : 'X';
        const currentScroll = isVertical ? scroller.scrollTop : scroller.scrollLeft;
        const scrollDelta = isVertical
            ? scroller.scrollHeight - scroller.offsetHeight
            : scroller.scrollWidth - scroller.offsetWidth;

        indicator.style.transform = `scale${scale}(${currentScroll / scrollDelta})`;
    }
};

const onResize = () => {
    update();
};

const debouncedOnResize = debounce(onResize, 150);

function init(container: HTMLElement | Document = document) {
    const sectionsWithHiddenScrollBar = Array.from(container.querySelectorAll<HTMLElement>('.js-hidden-scrollbar'));

    sectionsWithHiddenScrollBar.forEach((section) => {
        const scrollbarBackground = section.querySelector('.js-hidden-scrollbar-background');
        const indicator = section.querySelector('.js-hidden-scrollbar-indicator');
        if (section.scrollHeight > section.offsetHeight || section.scrollWidth > section.offsetWidth) {
            if (scrollbarBackground && indicator) {
                scrollbarBackground.classList.add('is-visible');
                section.addEventListener('scroll', onScroll);
            }
        } else {
            if (scrollbarBackground && indicator) {
                scrollbarBackground.classList.remove('is-visible');
                section.removeEventListener('scroll', onScroll);
            }
        }
    });

    window.addEventListener('resize', debouncedOnResize);
}

function destroy(container: HTMLElement | Document = document) {
    const sectionsWithHiddenScrollBar = Array.from(container.querySelectorAll<HTMLElement>('.js-hidden-scrollbar'));

    sectionsWithHiddenScrollBar.forEach((section) => {
        const scrollbarBackground = section.querySelector('.js-hidden-scrollbar-background');

        if (scrollbarBackground) {
            scrollbarBackground.classList.remove('is-visible');
            section.removeEventListener('scroll', onScroll);
        }
    });

    window.removeEventListener('resize', debouncedOnResize);
}

function update() {
    const sectionsWithHiddenScrollBar = Array.from(document.querySelectorAll<HTMLElement>('.js-hidden-scrollbar'));

    sectionsWithHiddenScrollBar.forEach((section) => {
        const scrollbarBackground = section.querySelector('.js-hidden-scrollbar-background');
        const indicator = section.querySelector('.js-hidden-scrollbar-indicator');
        if (section.scrollHeight > section.offsetHeight || section.scrollWidth > section.offsetWidth) {
            if (scrollbarBackground && indicator) {
                scrollbarBackground.classList.add('is-visible');
                section.addEventListener('scroll', onScroll);
            }
        } else {
            if (scrollbarBackground && indicator) {
                scrollbarBackground.classList.remove('is-visible');
                section.removeEventListener('scroll', onScroll);
            }
        }
    });
}

const _module = { init, destroy, update };

export default _module;
