let pos = { top: 0, left: 0, x: 0, y: 0 };
let element: HTMLElement | null;

const mouseDownHandler = (event: MouseEvent) => {
    if (element) {
        element.style.cursor = 'grabbing';
        element.style.userSelect = 'none';
        pos = {
            left: element.scrollLeft,
            top: element.scrollTop,

            x: event.clientX,
            y: event.clientY,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }
};

const mouseMoveHandler = function (event: MouseEvent) {
    if (element) {
        const dx = event.clientX - pos.x;
        const dy = event.clientY - pos.y;

        element.scrollTop = pos.top - dy;
        element.scrollLeft = pos.left - dx;
    }
};

const mouseUpHandler = function () {
    if (element) {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        element.style.cursor = 'grab';
        element.style.removeProperty('user-select');
    }
};

function init(container: HTMLElement | Document = document) {
    element = container.querySelector<HTMLElement>('.js-drag-scroll');
    if (element) {
        element.addEventListener('mousedown', mouseDownHandler);
    }
}

function destroy(container: HTMLElement | Document = document) {
    element = container.querySelector<HTMLElement>('.js-drag-scroll');
    if (element) {
        element.removeEventListener('mousedown', mouseDownHandler);
    }
}

const _module = { init, destroy };

export default _module;
