import { timeout } from '../utils/timeout';
import hiddenScrollbarTrack from './hidden-scrollbar-track';
import detailCardHeroSlider from './detail-card-hero-slider';
import gsap from 'gsap';

let triggers: HTMLElement[];
let popups: HTMLElement[];
let closeButtons: HTMLElement[];
let parentPopup: HTMLElement | null;

let prevSlug = '';
let activeSlug = '';

const onOpen = () => {
    detailCardHeroSlider.disable();
    if (parentPopup) {
        const scroller = parentPopup.querySelector<HTMLElement>('.lit-popup-container');
        if (scroller) {
            gsap.to(scroller, {
                scrollTo: {
                    y: 0,
                },
                overwrite: true,
                onComplete: () => {
                    if (parentPopup) {
                        if (scroller.scrollHeight > innerHeight) {
                            parentPopup.classList.add('no-scroll');
                        }
                    }
                },
                duration: 0.3,
            });
        }
    }
};

const onClose = () => {
    detailCardHeroSlider.enable();
    if (parentPopup) {
        parentPopup.classList.remove('no-scroll');
    }
};

const setActivePopup = async (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const trigger = eventTarget.closest<HTMLElement>('.js-card-popup-trigger');

    onOpen();

    if (trigger) {
        prevSlug = activeSlug;
        activeSlug = trigger.dataset.trigger || '';

        if (activeSlug === prevSlug) {
            return;
        }

        const prevActivePopup = popups.find((popup) => popup.dataset.name === prevSlug);
        const prevActiveTrigger = triggers.find((trigger) => trigger.dataset.trigger === prevSlug);

        if (prevActiveTrigger) {
            prevActiveTrigger.classList.remove('is-active');
        }

        const activeTrigger = triggers.find((trigger) => trigger.dataset.trigger === activeSlug);

        if (activeTrigger) {
            activeTrigger.classList.add('is-active');
        }

        if (prevActivePopup) {
            prevActivePopup.classList.add('is-out');
            await timeout(300);
            prevActivePopup.classList.remove('is-in');
            prevActivePopup.classList.remove('is-out');
            prevActivePopup.classList.add('visually-hidden');
        }

        const activePopup = popups.find((popup) => popup.dataset.name === activeSlug);
        if (activePopup) {
            activePopup.classList.add('is-in');
            activePopup.classList.remove('visually-hidden');
            hiddenScrollbarTrack.update();
        }
    }
};

const closeActivePopup = async () => {
    onClose();
    const activePopup = popups.find((popup) => popup.dataset.name === activeSlug);
    const activeTrigger = triggers.find((trigger) => trigger.dataset.trigger === activeSlug);
    prevSlug = '';
    activeSlug = '';

    if (activeTrigger) {
        activeTrigger.classList.remove('is-active');
    }

    if (activePopup) {
        activePopup.classList.add('is-out');
        await timeout(300);
        activePopup.classList.remove('is-in');
        activePopup.classList.remove('is-out');
        activePopup.classList.add('visually-hidden');
    }
};

const onClickOutside = (event: Event) => {
    const eventTarget = event.target as HTMLElement;

    if (!(eventTarget.closest('.js-card-popup-trigger') || eventTarget.closest('.js-card-popup'))) {
        closeActivePopup();
    }
};

function init(container: HTMLElement) {
    parentPopup = container;

    triggers = Array.from(container.querySelectorAll<HTMLElement>('.js-card-popup-trigger'));
    popups = Array.from(container.querySelectorAll<HTMLElement>('.js-card-popup'));
    closeButtons = Array.from(container.querySelectorAll<HTMLElement>('.js-detail-card-popup-close'));
    parentPopup.addEventListener('click', onClickOutside);

    triggers.forEach((trigger) => {
        trigger.addEventListener('click', setActivePopup);
    });

    closeButtons.forEach((button) => {
        button.addEventListener('click', closeActivePopup);
    });
}

function destroy(container: HTMLElement | Document = document) {
    triggers = Array.from(container.querySelectorAll<HTMLElement>('.js-card-popup-trigger'));
    popups = Array.from(container.querySelectorAll<HTMLElement>('.js-card-popup'));
    closeButtons = Array.from(container.querySelectorAll<HTMLElement>('.js-detail-card-popup-close'));

    if (parentPopup) {
        parentPopup.removeEventListener('click', onClickOutside);
        parentPopup = null;
    }

    triggers.forEach((trigger) => {
        trigger.removeEventListener('click', setActivePopup);
    });

    closeButtons.forEach((button) => {
        button.removeEventListener('click', closeActivePopup);
    });
    container.removeEventListener('click', onClickOutside);
    closeActivePopup();
}

const _module = { init, destroy };

export default _module;
