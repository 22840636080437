import { Swiper } from 'swiper';

let swiperInstance: Swiper | null;
let swiperEl: HTMLElement | null;
let cursorEl: HTMLElement | null;

const onMouseEnter = () => {
    if (cursorEl) {
        cursorEl.classList.add('is-active');
    }
};

const onMouseLeave = () => {
    if (cursorEl) {
        cursorEl.classList.remove('is-active');
    }
};

const onPointerMove = (event: MouseEvent) => {
    if (cursorEl) {
        cursorEl.style.setProperty('--cursor-x', `${event.clientX}px`);
        cursorEl.style.setProperty('--cursor-y', `${event.clientY}px`);
    }
};

function init(container: HTMLElement | Document = document) {
    swiperEl = container.querySelector<HTMLElement>('.js-detail-card-recommendations-slider');
    cursorEl = container.querySelector<HTMLElement>('.js-recommendations-cursor');

    if (swiperEl) {
        swiperInstance = new Swiper(swiperEl, {
            slidesPerView: 3,
            spaceBetween: 11,
            loop: true,
            breakpoints: {
                200: {
                    slidesPerView: 1.42,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 3,
                },
                1800: {
                    slidesPerView: 3,
                    spaceBetween: 11,
                },
            },
        });

        swiperEl.addEventListener('mouseenter', onMouseEnter);
        swiperEl.addEventListener('mouseleave', onMouseLeave);
        swiperEl.addEventListener('pointermove', onPointerMove);
    }
}

function destroy() {
    if (swiperInstance) {
        swiperInstance.destroy();
        swiperInstance = null;
    }

    if (swiperEl) {
        swiperEl.removeEventListener('mouseenter', onMouseEnter);
        swiperEl.removeEventListener('mouseleave', onMouseLeave);
        swiperEl.removeEventListener('pointermove', onPointerMove);
        swiperEl = null;
    }

    if (cursorEl) {
        cursorEl.classList.remove('is-active');
        cursorEl = null;
    }
}

const _module = { init, destroy };

export default _module;
