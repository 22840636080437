import gsap from 'gsap';

let timer: NodeJS.Timeout;

type Controls = {
    video: HTMLVideoElement | null | undefined;
    playpause: HTMLElement | null | undefined;
    soundControl: HTMLElement[];
    controls: HTMLElement | null | undefined;
    playStateCurrent: HTMLElement | null | undefined;
    fullscreenRequest: HTMLElement | null | undefined;
    fullscreenExit: HTMLElement | null | undefined;
    isSoundEnabled: boolean;
    loaded: boolean;
};

const map = new WeakMap<HTMLElement, Controls>();

function showControls(controls: Controls) {
    controls.controls?.classList.remove('is-hide');
}

function hideControls(controls: Controls) {
    controls.controls?.classList.add('is-hide');
}

const isFullscreenOpened = () => {
    return (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        document.webkitCurrentFullScreenElement
    );
};

const onFullScreenChange = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const isFullscreen = isFullscreenOpened();
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (event.target instanceof HTMLVideoElement) {
            event.target.muted = isFullscreen;
        } else {
            if (videoWrapper) {
                if (isFullscreen) {
                    videoWrapper?.classList.add('is-fullscreen');

                    const controls = map.get(videoWrapper);
                    if (controls) {
                        if (controls.video) {
                            videoWrapper?.classList.add('is-sound-enabled');
                            controls.video.muted = false;
                            controls.isSoundEnabled = true;
                        }
                    }
                } else {
                    videoWrapper?.classList.remove('is-fullscreen');

                    const controls = map.get(videoWrapper);
                    if (controls) {
                        if (controls.video) {
                            videoWrapper?.classList.remove('is-sound-enabled');
                            controls.video.muted = true;
                            controls.isSoundEnabled = false;
                        }
                    }
                }
            }
        }
    }
};

const onPlayZoneClick = (event: Event) => {
    if (event.currentTarget instanceof HTMLElement) {
        const videoWrapper = event.currentTarget.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                if (controls.video && controls.video.paused) {
                    controls.video.play();
                    controls.playpause?.classList.add('is-play');
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        hideControls(controls);
                    }, 1000);
                } else {
                    controls.video?.pause();
                    controls.playpause?.classList.remove('is-play');
                }
            }
        }
    }
};

type VideoElement = (HTMLVideoElement & { webkitEnterFullScreen(): Promise<void> }) | null;

const onFullscreenRequest = (event: Event) => {
    if (event.currentTarget instanceof HTMLElement) {
        const videoWrapper = event.currentTarget.closest<HTMLElement>('.js-video-wrapper') as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };
        if (videoWrapper) {
            if (videoWrapper.requestFullscreen) {
                videoWrapper.requestFullscreen();
            } else if (videoWrapper.webkitRequestFullscreen) {
                videoWrapper.webkitRequestFullscreen();
            } else if (videoWrapper.mozRequestFullScreen) {
                videoWrapper.mozRequestFullScreen();
            } else if (videoWrapper.msRequestFullscreen) {
                videoWrapper.msRequestFullscreen();
            } else {
                const video = videoWrapper.querySelector<HTMLVideoElement>('video') as VideoElement;

                if (video) {
                    video.webkitEnterFullScreen();
                }
            }
        }
    }
};

const onFullscreenExit = () => {
    const isFullscreen = isFullscreenOpened();
    if (isFullscreen) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE/Edge */
            document.msExitFullscreen();
        }
    }
};

const onSoundControlClick = (event: Event) => {
    event.stopPropagation();
    if (event.currentTarget instanceof HTMLElement || event.currentTarget instanceof HTMLButtonElement) {
        const videoWrapper = event.currentTarget.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                if (controls.video) {
                    if (controls.isSoundEnabled) {
                        videoWrapper?.classList.remove('is-sound-enabled');
                        controls.video.muted = true;
                        controls.isSoundEnabled = false;
                    } else {
                        videoWrapper?.classList.add('is-sound-enabled');
                        controls.video.muted = false;
                        controls.isSoundEnabled = true;
                    }
                }
                clearTimeout(timer);
                timer = setTimeout(() => {
                    hideControls(controls);
                }, 1000);
            }
        }
    }
};

const onVideoClick = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                clearTimeout(timer);
                showControls(controls);
            }
        }
    }
};

const onVideoEnded = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                controls.video?.pause();
                controls.playpause?.classList.remove('is-play');
                controls.video?.load();
                clearTimeout(timer);
                showControls(controls);
            }
        }
    }
};

const onVideoPlay = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                controls.playpause?.classList.add('is-play');
                clearTimeout(timer);
                timer = setTimeout(() => {
                    hideControls(controls);
                }, 1000);
            }
        }
    }
};

const onVideoPause = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                controls.playpause?.classList.remove('is-play');
                clearTimeout(timer);
                showControls(controls);
            }
        }
    }
};

const onVideoTimeUpdate = (event: Event) => {
    if (event.target instanceof HTMLElement) {
        const videoWrapper = event.target.closest<HTMLElement>('.js-video-wrapper');
        if (videoWrapper) {
            const controls = map.get(videoWrapper);
            if (controls) {
                if (controls.playStateCurrent && controls.video) {
                    gsap.to(controls.playStateCurrent, {
                        scaleX: controls.video.currentTime / controls.video.duration,
                    });
                }
            }
        }
    }
};

function init(container: HTMLElement | Document = document) {
    const videoWrapper = container.querySelector<HTMLElement>('.js-video-wrapper');
    if (videoWrapper) {
        const controls = {
            video: videoWrapper.querySelector<HTMLVideoElement>('.js-controled-video'),
            playpause: videoWrapper.querySelector<HTMLElement>('.js-video-button'),
            soundControl: Array.from(videoWrapper.querySelectorAll<HTMLElement>('.js-video-sound')),
            controls: videoWrapper.querySelector<HTMLElement>('.js-video-controls'),
            playStateCurrent: videoWrapper.querySelector<HTMLElement>('.js-play-state-current'),
            fullscreenRequest: videoWrapper.querySelector<HTMLElement>('.js-request-fullscreen'),
            fullscreenExit: videoWrapper.querySelector<HTMLElement>('.js-fullscreen-exit'),
            isSoundEnabled: false,
            loaded: false,
        };

        map.set(videoWrapper, controls);

        if (controls.video) {
            document.addEventListener('fullscreenchange', onFullScreenChange);
            document.addEventListener('webkitfullscreenchange', onFullScreenChange);
            document.addEventListener('mozfullscreenchange', onFullScreenChange);

            controls.video.addEventListener('click', onVideoClick);

            controls.video.addEventListener('play', onVideoPlay);

            controls.video.addEventListener('pause', onVideoPause);

            controls.video.addEventListener('ended', onVideoEnded);

            controls.video.addEventListener('timeupdate', onVideoTimeUpdate);

            if (controls.controls) {
                controls.controls.addEventListener('click', onPlayZoneClick);
            }
            if (controls.fullscreenRequest) {
                controls.fullscreenRequest.addEventListener('click', onFullscreenRequest);
            }
            if (controls.fullscreenExit) {
                controls.fullscreenExit.addEventListener('click', onFullscreenExit);
            }

            controls.soundControl.forEach((item) => {
                item.addEventListener('click', onSoundControlClick);
            });
        }
    }
}

function destroy(container: HTMLElement | Document = document) {
    document.removeEventListener('fullscreenchange', onFullScreenChange);
    document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
    document.removeEventListener('mozfullscreenchange', onFullScreenChange);
    const videoWrapper = container.querySelector<HTMLElement>('.js-video-wrapper');

    if (videoWrapper) {
        const controls = map.get(videoWrapper);

        if (controls) {
            if (controls.video) {
                controls.video.removeEventListener('click', onVideoClick);

                controls.video.removeEventListener('play', onVideoPlay);

                controls.video.removeEventListener('pause', onVideoPause);

                controls.video.removeEventListener('ended', onVideoEnded);

                controls.video.removeEventListener('timeupdate', onVideoTimeUpdate);
            }

            if (controls.controls) {
                controls.controls.removeEventListener('click', onPlayZoneClick);
            }

            if (controls.fullscreenRequest) {
                controls.fullscreenRequest.removeEventListener('click', onFullscreenRequest);
            }
            if (controls.fullscreenExit) {
                controls.fullscreenExit.removeEventListener('click', onFullscreenExit);
            }

            controls.soundControl.forEach((item) => {
                item.removeEventListener('click', onSoundControlClick);
            });
        }

        map.delete(videoWrapper);
    }
}

const _module = { init, destroy };

export default _module;
