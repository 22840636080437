import { LitPopupElement } from '@/custom-elements/LitPopupElement/LitPopupElement';
import dragScroll from './drag-scroll';
import { getCSSCustomProp } from '../utils/css';
import { lazyload } from './lazyload';

let video: HTMLVideoElement | undefined | null;
let indexOfVideoTab: number | undefined | null;

const onTabChange = (event: Event) => {
    const tabs = event.target as HTMLElement;
    const triggers = Array.from(tabs.querySelectorAll<HTMLElement>('[data-tabs-toggler]'));
    const activeTrigger = triggers.find((trigger) => trigger.hasAttribute('data-active'));
    const indexOfActiveTrigger = triggers.findIndex((item) => item === activeTrigger);
    const prevButton = document.querySelector('.js-to-prev-tab');
    const nextButton = document.querySelector('.js-to-next-tab');

    if (prevButton) {
        if (indexOfActiveTrigger === 0) {
            prevButton.setAttribute('disabled', '');
        } else {
            prevButton.removeAttribute('disabled');
        }
    }

    if (nextButton) {
        if (indexOfActiveTrigger === triggers.length - 1) {
            nextButton.setAttribute('disabled', '');
        } else {
            nextButton.removeAttribute('disabled');
        }
    }

    if (tabs) {
        const scroller = tabs.closest('.js-zoom-popup-container');

        if (scroller) {
            scroller.scrollTo(0, 0);
        }
    }

    if (typeof indexOfVideoTab === 'number') {
        const contentTabs = Array.from(tabs.querySelectorAll<HTMLElement>('.js-zoom-popup-content'));
        if (contentTabs[indexOfVideoTab]) {
            const video = contentTabs[indexOfVideoTab].querySelector<HTMLVideoElement>('video');
            if (video) {
                if (indexOfVideoTab === indexOfActiveTrigger) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        }
    }
};

const onNextClick = (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const button = eventTarget.closest('.js-to-next-tab');
    if (button && button.hasAttribute('disabled')) {
        return;
    }
    const triggers = Array.from(document.querySelectorAll<HTMLElement>('.js-zoom-popup-tab-trigger'));
    const activeTrigger = triggers.find((trigger) => trigger.hasAttribute('data-active'));
    const indexOfActive = triggers.findIndex((trigger) => trigger === activeTrigger);
    if (triggers[indexOfActive + 1]) {
        triggers[indexOfActive + 1].dispatchEvent(new Event('click'));
    }
};

const onPrevClick = (event: Event) => {
    const eventTarget = event.target as HTMLElement;
    const button = eventTarget.closest('.js-to-prev-tab');
    if (button && button.hasAttribute('disabled')) {
        return;
    }
    const triggers = Array.from(document.querySelectorAll<HTMLElement>('.js-zoom-popup-tab-trigger'));
    const activeTrigger = triggers.find((trigger) => trigger.hasAttribute('data-active'));
    const indexOfActive = triggers.findIndex((trigger) => trigger === activeTrigger);

    if (triggers[indexOfActive - 1]) {
        triggers[indexOfActive - 1].dispatchEvent(new Event('click'));
    }
};

const onOpen = (event: CustomEvent) => {
    const trigger = event.detail.triggerElement as HTMLElement;
    const popup = event.target as HTMLElement;
    const triggers = Array.from(popup.querySelectorAll<HTMLElement>('.js-zoom-popup-tab-trigger'));
    lazyload.update();
    triggers.forEach((trigger, i) => {
        const triggerWithVideo = trigger.querySelector('video');
        if (triggerWithVideo) {
            triggerWithVideo.play();
            indexOfVideoTab = i;
        }
    });

    const parentSlider = trigger.closest<HTMLElement>('.js-detail-card-hero-slider');

    if (parentSlider) {
        video = parentSlider.querySelector('video');

        if (video) {
            video.pause();
        }
    }

    const tabs = popup.querySelector('app-tabs');
    if (tabs) {
        tabs.addEventListener('tab-change', onTabChange);
    }

    dragScroll.init(popup);

    const currentTab = trigger.dataset.photo;

    const prevButton = popup.querySelector('.js-to-prev-tab');
    const nextButton = popup.querySelector('.js-to-next-tab');

    if (prevButton) {
        prevButton.addEventListener('click', onPrevClick);
    }

    if (nextButton) {
        nextButton.addEventListener('click', onNextClick);
    }

    if (currentTab) {
        const triggers = Array.from(popup.querySelectorAll<HTMLElement>('[data-tabs-toggler]'));
        const activeTrigger = popup.querySelector<HTMLElement>(`[data-tabs-toggler=${currentTab}]`);
        const activeTab = popup.querySelector<HTMLElement>(`[data-tabs-content=${currentTab}]`);
        const tabsWrapper = popup.querySelector('app-tabs');

        if (activeTab) {
            const video = activeTab.querySelector<HTMLVideoElement>('video');
            if (video) {
                video.play();
            }
        }

        const indexOfActiveTrigger = triggers.findIndex((item) => item === activeTrigger);

        if (tabsWrapper) {
            tabsWrapper.setAttribute('selected', indexOfActiveTrigger.toString());
        }

        if (indexOfActiveTrigger > 0) {
            const slugOfPrev = triggers[indexOfActiveTrigger - 1].getAttribute('data-tabs-toggler');
            if (slugOfPrev) {
                prevButton?.removeAttribute('disabled');
            } else {
                prevButton?.setAttribute('disabled', '');
            }
        } else {
            prevButton?.setAttribute('disabled', '');
        }

        if (indexOfActiveTrigger < triggers.length - 1) {
            const slugOfNext = triggers[indexOfActiveTrigger + 1].getAttribute('data-tabs-toggler');

            if (slugOfNext) {
                nextButton?.removeAttribute('disabled');
            } else {
                nextButton?.setAttribute('disabled', '');
            }
        } else {
            nextButton?.setAttribute('disabled', '');
        }

        activeTrigger?.setAttribute('data-active', '');
        activeTab?.removeAttribute('hidden');
    }
};

const findAndPauseVideoInArrayOfElements = (array: HTMLElement[]) => {
    array.forEach((item) => {
        const video = item.querySelector('video');
        if (video) {
            video.pause();
        }
    });
};

const onClose = (event: CustomEvent) => {
    const popup = event.target as HTMLElement;

    const tabs = popup.querySelector('app-tabs');
    if (tabs) {
        tabs.removeEventListener('tab-change', onTabChange);
        const triggers = Array.from(tabs.querySelectorAll<HTMLElement>('[data-tabs-toggler]'));
        const contents = Array.from(tabs.querySelectorAll<HTMLElement>(`[data-tabs-content]`));

        findAndPauseVideoInArrayOfElements(triggers);
        findAndPauseVideoInArrayOfElements(contents);

        const leaveDurationInSeconds = getCSSCustomProp(popup, '--leave-duration', 'number') as number;

        setTimeout(() => {
            triggers.forEach((trigger) => trigger.removeAttribute('data-active'));
            contents.forEach((content) => content.setAttribute('hidden', ''));
        }, leaveDurationInSeconds * 1000);
    }

    dragScroll.destroy(popup);

    const prevButton = popup.querySelector('.js-to-prev-tab');
    const nextButton = popup.querySelector('.js-to-next-tab');

    if (prevButton) {
        prevButton.removeEventListener('click', onPrevClick);
    }

    if (nextButton) {
        nextButton.removeEventListener('click', onNextClick);
    }

    if (video) {
        video.play();
        video = null;
    }
};

function init(container: HTMLElement | Document = document) {
    const zoomPopup = container.querySelector<LitPopupElement>('[data-lit-popup="detail-card-zoom"]');

    if (zoomPopup) {
        zoomPopup.addEventListener('open', onOpen);
        zoomPopup.addEventListener('close', onClose);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const zoomPopup = container.querySelector<LitPopupElement>('[data-lit-popup="detail-card-zoom"]');

    if (zoomPopup) {
        zoomPopup.removeEventListener('open', onOpen);
        zoomPopup.removeEventListener('close', onClose);
    }

    video = null;
}

const _module = { init, destroy };

export default _module;
