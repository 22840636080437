import { timeout } from '../utils/timeout';
import { IViewData } from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ease1 } from '../animations/easings';

const duration = 1;

export default {
    name: 'default page transition',
    sync: true,

    async leave({ current }: IViewData) {
        const currentContainer = current.container;
        const footer = document.querySelector('.js-footer');
        const currentContainerHeight = currentContainer.offsetHeight;

        const appCategoriesBlock = currentContainer.querySelector<HTMLElement>('app-categories-block');
        const catalogStickyElement = currentContainer.querySelector<HTMLElement>('.js-catalog-section-sticky-element');
        const currentContainerWrapper = currentContainer.querySelector('.page-transition');
        let topPositionOfCatalogStickyElement = 0;
        if (catalogStickyElement && appCategoriesBlock) {
            topPositionOfCatalogStickyElement = scrollY - appCategoriesBlock.offsetTop;
        }
        gsap.timeline()
            .set(catalogStickyElement, {
                position: 'absolute',
                top: topPositionOfCatalogStickyElement,
                width: '100%',
            })
            .set(currentContainer, {
                position: 'fixed',
                width: '100%',
                left: '0',
                overflow: 'hidden',
                top: scrollY * -1,
            })
            .set(footer, {
                position: 'fixed',
                width: '100%',
                left: 0,
                zIndex: -1,
                top: currentContainerHeight - scrollY,
            })
            .fromTo(
                currentContainer,
                {
                    x: '0',
                    duration: duration,
                    ease: ease1,
                },
                {
                    x: '100%',
                    duration: duration,
                    ease: ease1,
                },
                0,
            )
            .fromTo(
                currentContainerWrapper,
                {
                    x: '0',
                    duration: duration,
                    ease: ease1,
                },
                {
                    x: '-100%',
                    duration: duration,
                    ease: ease1,
                },
                0,
            )
            .set(footer, {
                position: 'unset',
                left: 'unset',
                top: 'unset',
                zIndex: 'unset',
            })
            .set(currentContainer, {
                clearProps: 'overflow',
            });

        await timeout(1000 * duration);
    },

    enter({ next }: IViewData) {
        const scrollTriggers = ScrollTrigger.getAll();
        if (scrollTriggers && scrollTriggers.length > 0) {
            scrollTriggers.forEach((st) => {
                st.refresh();
            });
        }

        const nextContainer = next.container;
        const nextContainerWrapper = nextContainer.querySelector('.page-transition');

        gsap.timeline()
            .add(() => {
                nextContainer.classList.add('is-transition');
            })
            .set(nextContainer, {
                pointerEvents: 'none',
                overflow: 'hidden',
            })
            .fromTo(
                nextContainer,
                {
                    x: '-100%',
                    duration: duration,
                    ease: ease1,
                },
                {
                    x: '0',
                    duration: duration,
                    ease: ease1,
                },
                0,
            )
            .fromTo(
                nextContainerWrapper,
                {
                    x: '100%',
                    duration: duration,
                    ease: ease1,
                },
                {
                    x: '0',
                    duration: duration,
                    ease: ease1,
                },
                0,
            )
            .set(
                nextContainer,
                {
                    clearProps: 'all',
                },
                `+=0.1`,
            )
            .set(
                nextContainerWrapper,
                {
                    clearProps: 'all',
                },
                `+=0.1`,
            );
    },
};
