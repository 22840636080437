import axios from 'axios';

import detailCardHeroSlider from './detail-card-hero-slider';
import detailCardRecommendationsSlider from './detail-card-recommendations-slider';
import hiddenScrollbarTrack from './hidden-scrollbar-track';
import detailCardInnerPopups from './detail-card-inner-popups';
import detailCardPopupZoom from './detail-card-popup-zoom';
import gsap from 'gsap';
import { ease1 } from '../animations/easings';

const PRELOADER_LAYOUT = `
<div class="popup-preloader sequence">
    <app-img-sequence autoplay fps="4" img-width="320" img-height="320" frames="8">
        <div style="display: none">
            <picture>
                <source
                    srcset="/local/templates/main/static/img/preloader/preloader-sprite@resize-2560x.webp"
                    type="image/webp"
                />
                <img
                    alt=""
                    src="/local/templates/main/static/img/preloader/preloader-sprite@resize-2560x.png"
                    width="2560"
                    height="320"
                />
            </picture>
        </div>
    </app-img-sequence>
</div>
`;

export default function initDetailCardPopup() {
    const detailCardPopup = document.querySelector<HTMLElement>('[data-lit-popup="detail-card"]');
    const detailCardPopupZoomPopup = document.querySelector<HTMLElement>('[data-lit-popup="detail-card-zoom"]');

    const initDetailCardPopupScripts = (container: HTMLElement) => {
        detailCardHeroSlider.init(container);
        detailCardRecommendationsSlider.init(container);
        hiddenScrollbarTrack.init(container);
        detailCardInnerPopups.init(container);
        detailCardPopupZoom.init(document);
    };

    if (detailCardPopup && detailCardPopup.classList.contains('lit-popup--opened')) {
        const overlay = detailCardPopup.querySelector('.lit-popup-overlay');
        const container = detailCardPopup.querySelector('.lit-popup-container');
        gsap.set(container, {
            x: '100%',
            transition: '0s all',
        });
        gsap.set(overlay, {
            opacity: 0,
            transition: '0s all',
        });
        document.addEventListener(
            'preloader-complete',
            () => {
                initDetailCardPopupScripts(detailCardPopup);
                gsap.timeline()
                    .to(
                        container,
                        {
                            x: 0,
                        },
                        0,
                    )
                    .to(container, {
                        clearProps: 'all',
                    })
                    .to(
                        overlay,
                        {
                            opacity: 0.6,
                        },
                        0,
                    )
                    .to(overlay, {
                        clearProps: 'all',
                    });
            },
            { once: true },
        );
    }

    const destroyDetailCardPopupScripts = (container: HTMLElement) => {
        detailCardHeroSlider.destroy();
        detailCardRecommendationsSlider.destroy();
        hiddenScrollbarTrack.destroy(container);
        detailCardInnerPopups.destroy(container);
        detailCardPopupZoom.destroy(document);
    };

    const fetchContent = (endpoint: string) => {
        return new Promise((resolve) => {
            axios
                .get(endpoint, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                })
                .then((result) => {
                    resolve(result);
                });
        });
    };

    const changeContent = (event: Event) => {
        event.preventDefault();
        const eventTarget = event.target as HTMLElement;
        const newLink = eventTarget.closest<HTMLLinkElement>('.js-change-card');
        if (newLink) {
            if (detailCardPopup) {
                const container = detailCardPopup.querySelector<HTMLElement>('.lit-popup-container');

                gsap.timeline()
                    .set(container, { transition: 'transform 0s linear', ease: ease1, willChange: 'transform' })
                    .to(container, { x: '100%', duration: 1 })
                    .add(() => {
                        if (container) {
                            destroyDetailCardPopupScripts(container);
                        }
                        if (container) {
                            container.innerHTML = PRELOADER_LAYOUT;
                        } else {
                            detailCardPopup.innerHTML = PRELOADER_LAYOUT;
                        }

                        if (detailCardPopupZoomPopup) {
                            detailCardPopupZoomPopup.innerHTML = PRELOADER_LAYOUT;
                        }

                        const endpoint = newLink.getAttribute('href') || '';

                        fetchContent(endpoint)
                            .then((result: any) => {
                                const container = detailCardPopup.querySelector('.lit-popup-container');
                                if (container) {
                                    container.innerHTML = result.data.data.popupLayout;
                                } else {
                                    detailCardPopup.innerHTML = result.data.data.popupLayout;
                                }

                                const changeCardTriggers = Array.from(
                                    detailCardPopup.querySelectorAll('.js-change-card'),
                                );
                                changeCardTriggers.forEach((trigger) =>
                                    trigger.addEventListener('click', changeContent),
                                );

                                if (detailCardPopupZoomPopup) {
                                    detailCardPopupZoomPopup.innerHTML = result.data.data.popupZoomLayout;
                                }
                            })
                            .then(() => {
                                initDetailCardPopupScripts(detailCardPopup);
                            });
                    })
                    .to(container, { x: '0%', duration: 1, ease: ease1 })
                    .set(container, { clearProps: 'all' });
            }
        }
    };

    if (detailCardPopup) {
        detailCardPopup.addEventListener('open', (event: any) => {
            const endpoint = event.detail.triggerElement.dataset.endpoint;
            fetchContent(endpoint)
                .then((result: any) => {
                    const container = detailCardPopup.querySelector('.lit-popup-container');
                    if (container) {
                        container.innerHTML = result.data.data.popupLayout;
                    } else {
                        detailCardPopup.innerHTML = result.data.data.popupLayout;
                    }

                    const cardNav = detailCardPopup.querySelector<HTMLElement>('.js-card-hero-nav-wrapper');
                    const cardNavWrapper = detailCardPopup.querySelector<HTMLElement>('.js-card-hero-nav-wrapper');

                    if (cardNav && cardNavWrapper) {
                        const cardNavHeight = cardNav.offsetHeight;
                        cardNavWrapper.style.setProperty('--card-nav-height', `${cardNavHeight}px`);
                    }

                    if (endpoint) {
                        history.pushState({}, '', `${endpoint}`);
                    }

                    const changeCardTriggers = Array.from(detailCardPopup.querySelectorAll('.js-change-card'));
                    changeCardTriggers.forEach((trigger) => trigger.addEventListener('click', changeContent));

                    if (detailCardPopupZoomPopup) {
                        detailCardPopupZoomPopup.innerHTML = result.data.data.popupZoomLayout;
                    }
                })
                .then(() => {
                    initDetailCardPopupScripts(detailCardPopup);
                });
        });

        detailCardPopup.addEventListener('close-complete', () => {
            destroyDetailCardPopupScripts(detailCardPopup);

            const barbaContainer = document.querySelector<HTMLElement>('[data-barba-namespace]');
            if (barbaContainer) {
                const namespace = barbaContainer.dataset.barbaNamespace;
                if (namespace === 'index-page') {
                    history.pushState({}, '', location.pathname.split('catalog/')[0]);
                } else {
                    history.pushState({}, '', location.pathname.split('element/')[0]);
                }
            } else {
                history.pushState({}, '', location.pathname.split('element/')[0]);
            }

            const container = detailCardPopup.querySelector('.lit-popup-container');
            if (container) {
                container.innerHTML = PRELOADER_LAYOUT;
            } else {
                detailCardPopup.innerHTML = PRELOADER_LAYOUT;
            }

            if (detailCardPopupZoomPopup) {
                detailCardPopupZoomPopup.innerHTML = PRELOADER_LAYOUT;
            }
        });
    }
}
