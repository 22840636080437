import debounce from 'lodash.debounce';

export default () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const calculateVhOnResize = () => {
        const newVh = visualViewport ? visualViewport.height * 0.01 : window.innerHeight * 0.01;

        if (newVh > vh) {
            document.documentElement.style.setProperty('--vh', `${newVh}px`);
        }
    };

    const calculateVhOnResizeDebounced = debounce(calculateVhOnResize, 400);

    const calculateVhOnOrientationChange = debounce(() => {
        if (!window.matchMedia('(max-width: 1024px)').matches) {
            return;
        }

        const newVh = visualViewport ? visualViewport.height * 0.01 : window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
    }, 400);

    if (visualViewport) {
        visualViewport.addEventListener('resize', calculateVhOnResize);
    } else {
        window.addEventListener('resize', calculateVhOnResizeDebounced);
    }

    window.addEventListener('orientationchange', calculateVhOnOrientationChange);

    return () => {
        if (visualViewport) {
            visualViewport.removeEventListener('resize', calculateVhOnResize);
        } else {
            window.removeEventListener('resize', calculateVhOnResizeDebounced);
        }
        window.removeEventListener('orientationchange', calculateVhOnOrientationChange);
    };
};
