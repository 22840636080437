import gsap from 'gsap';
import { ease1 } from '../animations/easings';

export const preloaderAnimationDuration = 1.5; // sec

function createPreloader() {
    const preloader = document.querySelector<HTMLElement>('.js-preloader');
    const main = document.querySelector<HTMLElement>('.main');
    const mainWrapper = document.querySelector<HTMLElement>('.main-wrapper');
    const sequence = document.querySelector<HTMLElement>('.js-preloader-sequence');
    const sequenceWrapper = document.querySelector<HTMLElement>('.js-preloader-sequence-wrapper');
    const appNav = document.querySelector<HTMLElement>('.js-app-nav');

    const state = {
        completed: false,
        currentPercent: 0,
    };

    window.scrollTo(0, 0);

    document.body.classList.add('no-scroll');

    function leave(): Promise<void> {
        return new Promise((resolve) => {
            document.dispatchEvent(new Event('preloader-leave'));
            const barbaNamespaceEl = main?.querySelector<HTMLElement>('[data-barba="container"]');
            const currentNamespace = barbaNamespaceEl ? barbaNamespaceEl.dataset.barbaNamespace : '';
            const tl = gsap.timeline({ defaults: { duration: 1, ease: ease1 } });
            const footer = document.querySelector<HTMLElement>('.js-footer');
            const indexLogo = document.querySelector<HTMLElement>('.js-index-slider-logo');

            if (main) {
                if (currentNamespace === 'index-page') {
                    tl.set(mainWrapper, {
                        overflow: 'hidden',
                        height: '100vh',
                        scale: 0.5,
                        borderRadius: 20,
                        willChange: 'transform',
                    })
                        .set(main, {
                            y: '120vh',
                            scale: 1.15,
                            transformOrigin: `center ${main.offsetHeight / innerHeight}%`,
                            willChange: 'transform',
                        })
                        .to(sequenceWrapper, { y: '-100%', duration: 0.75 }, 1)
                        .to(
                            sequence,
                            {
                                y: '100%',
                                duration: 0.75,
                                onComplete: () => {
                                    preloader?.remove();
                                },
                            },
                            1,
                        )
                        .to(main, { y: 0 }, 1)
                        .to(mainWrapper, { scale: 1, borderRadius: 0 }, 2)
                        .to(main, { scale: 1 }, 2)
                        .fromTo(
                            indexLogo,
                            {
                                y: -10,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                            },
                            2.2,
                        )
                        .fromTo(
                            appNav,
                            { clipPath: 'polygon(0 0, 100% 0%, 100% 0, 0 0)' },
                            { clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)' },
                            2.2,
                        )
                        .add(() => {
                            resolve();
                            document.dispatchEvent(new Event('preloader-complete'));
                            setTimeout(() => {
                                document.body.classList.remove('no-scroll');
                            }, 300);
                        })
                        .set(mainWrapper, { clearProps: 'all' })
                        .set(main, { clearProps: 'all' })
                        .set(appNav, { clearProps: 'all' })
                        .from(footer, {
                            opacity: 0,
                            y: 20,
                        });
                } else {
                    const duration = 1;
                    const nextContainer = main.querySelector<HTMLElement>('[data-barba="container"]');
                    if (nextContainer) {
                        const nextContainerWrapper = nextContainer.querySelector('.page-transition');

                        gsap.timeline()
                            .set(nextContainer, { overflow: 'hidden' })
                            .set(preloader, { backgroundColor: 'transparent' })
                            .to(sequenceWrapper, { x: '-100%', duration: 0.75 }, 1)
                            .to(
                                sequence,
                                {
                                    x: '100%',
                                    duration: 0.75,
                                    onComplete: () => {
                                        preloader?.remove();
                                    },
                                },
                                1,
                            )
                            .add(() => {
                                nextContainer.classList.add('is-transition');
                            }, 1)
                            .set(
                                nextContainer,
                                {
                                    pointerEvents: 'none',
                                },
                                1,
                            )
                            .fromTo(
                                nextContainer,
                                {
                                    x: '-100%',
                                    duration: duration,
                                    ease: ease1,
                                },
                                {
                                    x: '0',
                                    duration: duration,
                                    ease: ease1,
                                },
                                1,
                            )

                            .fromTo(
                                nextContainerWrapper,
                                {
                                    x: '100%',
                                    duration: duration,
                                    ease: ease1,
                                },
                                {
                                    x: '0',
                                    duration: duration,
                                    ease: ease1,
                                },
                                1,
                            )
                            .fromTo(
                                appNav,
                                { clipPath: 'polygon(0 0, 100% 0%, 100% 0, 0 0)' },
                                { clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)' },
                            )
                            .add(() => {
                                resolve();
                                document.dispatchEvent(new Event('preloader-complete'));
                                setTimeout(() => {
                                    document.body.classList.remove('no-scroll');
                                }, 300);
                            })
                            .set(appNav, { clearProps: 'all' })
                            .set(nextContainer, {
                                clearProps: 'all',
                            })
                            .set(nextContainerWrapper, {
                                clearProps: 'all',
                            })
                            .from(footer, {
                                opacity: 0,
                                y: 20,
                            });
                    } else {
                        document.dispatchEvent(new Event('preloader-complete'));
                        setTimeout(() => {
                            document.body.classList.remove('no-scroll');
                        }, 300);
                    }
                }
            } else {
                document.dispatchEvent(new Event('preloader-complete'));
                setTimeout(() => {
                    document.body.classList.remove('no-scroll');
                }, 300);
            }
        });
    }

    function loadAsset(asset: HTMLImageElement | HTMLVideoElement): Promise<void> {
        return new Promise((resolve) => {
            if (asset instanceof HTMLImageElement) {
                if (asset.complete) {
                    resolve();
                } else {
                    asset.onload = () => resolve();
                    asset.onerror = () => resolve();
                }
            }

            if (asset instanceof HTMLVideoElement) {
                if (asset.readyState === 4) {
                    resolve();
                } else {
                    asset.addEventListener('loadedmetadata', () => resolve(), { once: true });
                }
            }
        });
    }

    async function loadAssetsFromElement(element: Element | Document = document) {
        const images = Array.from(element.querySelectorAll<HTMLImageElement>('img:not(.lazy):not([loading="lazy"])'));
        const videos = Array.from(element.querySelectorAll<HTMLImageElement>('video:not(.lazy):not([loading="lazy"])'));
        const assets: Array<HTMLImageElement | HTMLVideoElement> = [...images, ...videos];

        if (assets.length > 0) {
            await Promise.all<any>(assets.map((asset) => loadAsset(asset)));
        }
    }

    async function loadAssets() {
        await loadAssetsFromElement(document.body);
    }

    return { leave, loadAssets, state } as const;
}

export const preloader = createPreloader();
