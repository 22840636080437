import type { Swiper as SwiperType, SwiperOptions } from 'swiper';

const map = new Map<Element, any>();

const ARROW_LEFT = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 12.2363H1M1 12.2363L13.2012 2M1 12.2363L13.2012 22" stroke="white" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

const ARROW_LEFT_SMALL = `
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.42871 4.90506H1.22871M1.22871 4.90506L5.22184 1.62946M1.22871 4.90506L5.22184 8.02945" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

const ARROW_RIGHT = `
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11.9816H23M23 11.9816L10.7988 2M23 11.9816L10.7988 22" stroke="white" stroke-width="1.5"
        stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

const ARROW_RIGHT_SMALL = `
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.769531 4.90506H7.96953M7.96953 4.90506L3.9764 1.62946M7.96953 4.90506L3.9764 8.02945" stroke="#A9AAC8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

function generateNavButton(direction: 'prev' | 'next', size: string) {
    const button = document.createElement('button');
    button.className = `circle-button-icon circle-button-icon--${size} js-slider-${direction}`;
    button.setAttribute('aria-label', `${direction === 'prev' ? 'Предыдущий' : 'Следующий'} слайд`);
    button.innerHTML =
        direction === 'prev'
            ? size === 'small'
                ? ARROW_LEFT_SMALL
                : ARROW_LEFT
            : size === 'small'
            ? ARROW_RIGHT_SMALL
            : ARROW_RIGHT;
    return button;
}

function createNav(swiper: SwiperType, sliderNav?: Element | null, size = 'big') {
    if (sliderNav) {
        const navigateBackwards = (event: Event) => {
            swiper.slidePrev();
            const eventTarget = event.target as HTMLElement;
            const button = eventTarget.closest('button');
            if (swiper.isBeginning) {
                if (button) {
                    button.setAttribute('disabled', '');
                }
            } else {
                if (button) {
                    button.removeAttribute('disabled');
                }
            }
        };

        const navigateForwards = (event: Event) => {
            swiper.slideNext();
            const eventTarget = event.target as HTMLElement;
            const button = eventTarget.closest('button');
            if (swiper.isEnd) {
                if (button) {
                    button.setAttribute('disabled', '');
                }
            } else {
                if (button) {
                    button.removeAttribute('disabled');
                }
            }
        };

        const fragment = document.createDocumentFragment();
        const prevButton = generateNavButton('prev', size);
        fragment.appendChild(prevButton);
        prevButton.addEventListener('click', navigateBackwards);
        map.set(prevButton, navigateBackwards);

        const nextButton = generateNavButton('next', size);
        fragment.appendChild(nextButton);
        nextButton.addEventListener('click', navigateForwards);
        map.set(nextButton, navigateForwards);

        swiper.on('slideChange', (instance) => {
            if (instance.isBeginning) {
                prevButton.setAttribute('disabled', '');
            } else {
                prevButton.removeAttribute('disabled');
            }

            if (instance.isEnd) {
                nextButton.setAttribute('disabled', '');
            } else {
                nextButton.removeAttribute('disabled');
            }
        });

        swiper.on('init', (instance) => {
            if (instance.isBeginning) {
                prevButton.setAttribute('disabled', '');
            }

            if (instance.isEnd) {
                nextButton.setAttribute('disabled', '');
            }
        });

        sliderNav.appendChild(fragment);
    }
}

function destroyNav(containerEl: Element) {
    const prevButton = containerEl.querySelector<HTMLElement>('.js-slider-prev');
    const nextButton = containerEl.querySelector<HTMLElement>('.js-slider-next');
    [prevButton, nextButton].forEach((btn) => {
        if (btn) {
            const listener = map.get(btn);

            if (listener) {
                btn.removeEventListener('click', listener);
                map.delete(btn);
                btn.remove();
            }
        }
    });
}

const _module = { createNav, destroyNav };

export default _module;
