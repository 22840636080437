import { Swiper } from 'swiper';
import swiperNav from '../utils/swiper-nav';

let swiperInstance: Swiper | null;
let swiperEl: HTMLElement | null;
let cursorEl: HTMLElement | null;

const onMouseEnter = () => {
    if (cursorEl) {
        cursorEl.classList.add('is-active');
    }
};

const onMouseLeave = () => {
    if (cursorEl) {
        cursorEl.classList.remove('is-active');
    }
};

const onPointerMove = (event: MouseEvent) => {
    if (cursorEl) {
        cursorEl.style.setProperty('--cursor-x', `${event.clientX}px`);
        cursorEl.style.setProperty('--cursor-y', `${event.clientY}px`);
    }
};

const onResize = () => {
    if (swiperInstance) {
        const slideWithVideo = swiperInstance.el.querySelector<HTMLElement>('.js-video-slide');
        if (slideWithVideo) {
            const videoOnSlide = slideWithVideo.querySelector<HTMLVideoElement>('.js-detail-card-video');
            const zoomPopupButtonMobile = document.querySelector<HTMLElement>('.js-zoom-popup-button');

            const currentSlide = swiperInstance.slides[swiperInstance.activeIndex];
            const nextSlide = swiperInstance.slides[swiperInstance.activeIndex + 1];
            if (
                slideWithVideo === currentSlide ||
                (window.matchMedia('(min-width: 1024px)').matches && nextSlide && slideWithVideo === nextSlide)
            ) {
                zoomPopupButtonMobile?.classList.add('is-hidden');
                videoOnSlide?.play();
            } else {
                zoomPopupButtonMobile?.classList.remove('is-hidden');
                videoOnSlide?.pause();
            }
        }
    }
};

function init(container: HTMLElement | Document = document) {
    swiperEl = container.querySelector<HTMLElement>('.js-detail-card-hero-slider');
    cursorEl = container.querySelector<HTMLElement>('.js-zoom-swiper-cursor');
    const sliderNav = container.querySelector<HTMLElement>('.js-detail-card-hero-slider-nav');
    const zoomPopupButtonMobile = container.querySelector<HTMLElement>('.js-zoom-popup-button');

    const slideWithVideo = container.querySelector<HTMLElement>('.js-video-slide');
    const videoOnSlide = container.querySelector<HTMLVideoElement>('.js-detail-card-video');

    if (swiperEl) {
        swiperInstance = new Swiper(swiperEl, {
            slidesPerView: 2,
            allowTouchMove: false,
            breakpoints: {
                200: {
                    slidesPerView: 1.08,
                    allowTouchMove: true,
                },
                1024: {
                    slidesPerView: 2,
                    allowTouchMove: true,
                },
                1280: {
                    allowTouchMove: false,
                },
            },
            on: {
                beforeInit: (swiper) => {
                    swiperNav.createNav(swiper, sliderNav);
                },
                init: (swiper) => {
                    const currentSlide = swiper.slides[swiper.activeIndex];
                    const nextSlide = swiper.slides[swiper.activeIndex + 1];
                    const withPopupSlides = swiper.slides.filter((slide) => slide.dataset.photo);
                    if (zoomPopupButtonMobile && withPopupSlides[0] instanceof HTMLElement) {
                        zoomPopupButtonMobile.dataset.photo = withPopupSlides[0].dataset.photo;
                    }
                    if (
                        slideWithVideo === currentSlide ||
                        (window.matchMedia('(min-width: 1024px)').matches && nextSlide && slideWithVideo === nextSlide)
                    ) {
                        videoOnSlide?.play();
                    }
                },
                slideChange: (swiper) => {
                    const currentSlide = swiper.slides[swiper.activeIndex];
                    const nextSlide = swiper.slides[swiper.activeIndex + 1];
                    if (slideWithVideo && videoOnSlide) {
                        if (
                            slideWithVideo === currentSlide ||
                            (window.matchMedia('(min-width: 1024px)').matches &&
                                nextSlide &&
                                slideWithVideo === nextSlide)
                        ) {
                            videoOnSlide.play();
                        } else {
                            videoOnSlide.pause();
                        }
                    }
                    if (zoomPopupButtonMobile && currentSlide instanceof HTMLElement) {
                        zoomPopupButtonMobile.dataset.photo = currentSlide.dataset.photo;
                    }
                },
                destroy: () => {
                    if (sliderNav) {
                        swiperNav.destroyNav(sliderNav);
                    }
                    if (videoOnSlide) {
                        videoOnSlide.pause();
                    }
                },
            },
        });

        swiperEl.addEventListener('mouseenter', onMouseEnter);
        swiperEl.addEventListener('mouseleave', onMouseLeave);
        swiperEl.addEventListener('pointermove', onPointerMove);
    }

    window.addEventListener('resize', onResize);
}

function destroy() {
    if (swiperInstance) {
        swiperInstance.destroy();
        swiperInstance = null;
    }

    if (swiperEl) {
        swiperEl.removeEventListener('mouseenter', onMouseEnter);
        swiperEl.removeEventListener('mouseleave', onMouseLeave);
        swiperEl.removeEventListener('pointermove', onPointerMove);
        swiperEl = null;
    }

    if (cursorEl) {
        cursorEl.classList.remove('is-active');
        cursorEl = null;
    }

    window.removeEventListener('resize', onResize);
}

function disable() {
    if (swiperInstance) {
        swiperInstance.disable();
        const el = swiperInstance.el;

        if (el) {
            el.classList.add('is-disabled');
        }
    }
}

function enable() {
    if (swiperInstance) {
        swiperInstance.enable();

        const el = swiperInstance.el;

        if (el) {
            el.classList.remove('is-disabled');
        }
    }
}

const _module = { init, destroy, disable, enable };

export default _module;
